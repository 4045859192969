import { ReturnHome } from './ReturnHome';


export const Privacy = () => <div> 
<ReturnHome/>
<div style={{textAlign: "center"}}>
      <h1>Privacy Policy for Comfort Journey App:-</h1>
      <p>
        At Comfort Journey, we are committed to protecting the privacy of our users. This Privacy Policy outlines how we collect, use, and disclose information when you use our app. Please read this policy carefully to understand our practices regarding your personal information.
      </p>
      <h2>Information We Collect:</h2>
      <ol>
        <li>
          <p>We want to assure you that we do not collect any personal information from our users. We do not require you to create an account or provide any personal details to use the Comfort Journey app.</p>
        </li>
      </ol>
      <h2>Non-Personal Information:</h2>
      <ol start="2">
        <li>
          <p>To improve our services and enhance your experience, we may collect non-personal information such as device information, app usage data, and anonymous analytics. This information is collected automatically and is used solely for internal purposes, including analyzing trends, monitoring app performance, and making enhancements.</p>
        </li>
      </ol>
      <h2>Cookies and Similar Technologies:</h2>
      <ol start="3">
        <li>
          <p>We do not use cookies or similar tracking technologies in the Comfort Journey app. Therefore, we do not store any information on your device or track your activities.</p>
        </li>
      </ol>
      <h2>Third-Party Services:</h2>
      <ol start="4">
        <li>
          <p>Comfort Journey app does not integrate any third-party services that may collect your personal information. We do not share, sell, or rent any information with third parties.</p>
        </li>
      </ol>
      <h2>Children's Privacy:</h2>
      <ol start="5">
        <li>
          <p>Our app is not directed towards individuals under the age of 13. We do not knowingly collect personal information from children. If you believe that we may have inadvertently collected personal information from a child, please contact us immediately, and we will take steps to delete it.</p>
        </li>
      </ol>
      <h2>Data Security:</h2>
      <ol start="6">
        <li>
          <p>We take reasonable measures to protect the information collected within the Comfort Journey app from unauthorized access, disclosure, alteration, or destruction. However, please note that no method of transmission or storage is completely secure, and we cannot guarantee absolute security.</p>
        </li>
      </ol>
      <h2>Changes to this Privacy Policy:</h2>
      <ol start="7">
        <li>
          <p>We reserve the right to modify or update this Privacy Policy from time to time. Any changes made will be reflected on this page with the updated effective date. We encourage you to review this Privacy Policy periodically to stay informed about how we protect your information.</p>
        </li>
      </ol>
      <h2>Contact Us:</h2>
      <ol start="8">
        <li>
          <p>If you have any questions, concerns, or suggestions regarding our Privacy Policy or your personal information, please contact us at <a href="mailto:info@comfortjourney.in">info@comfortjourney.in</a>.</p>
        </li>
      </ol>
      <p>
        By using the Comfort Journey app, you acknowledge that you have read and understood this Privacy Policy and agree to the collection and use of information as described herein.
      </p>
    </div>
     </div>